// @flow

import React from 'react';

type Props = {
  children?: React$Node,
}

type State = {

}

export default class SVGComponent extends React.PureComponent<Props, State> {
  static defaultProps = {
    children: null,
  }

  constructor ( props: Props ) {
    super( props );

    this.svgElement = React.createRef();
  }

  svgElement: { current: null | Element };

  render () {
    const { children } = this.props;

    return (
      <svg style={ { position: 'absolute' } } { ...this.props } ref={ this.svgElement }>
        <g>
          { children }
        </g>
      </svg>
    );
  }
}
