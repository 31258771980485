// @flow

import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import type {
  ActionsObservable,
  StateObservable,
} from 'redux-observable';

import ce from './ce';

import {
  CREATE_NODE, CONNECT_NODE, DISCONNECT_NODE,
  createNode, connectNode, disconnectNode,
  addNode, setNodeInputs, setNodeParams, setNodeSettings,
} from '../actions/nodes';
import { resonate, recompute } from '../actions/computes';
import { addConnector, removeConnector } from '../actions/connectors';

import type { STATE_TYPE } from '../data';
import type {
  CREATE_NODE_TYPE,
  CONNECT_NODE_TYPE,
  DISCONNECT_NODE_TYPE,

} from '../actions/nodes';

/* eslint max-len: ["error", { "code": 150 }] */

const nodes = ce( {
  [ CREATE_NODE ]: ( action$: ActionsObservable<createNode>, state$: StateObservable<STATE_TYPE> ) => action$.pipe(
    mergeMap( ( action: { payload: CREATE_NODE_TYPE, nid: string } ) => {
      const { nid } = action;
      const { nodeType, x, y } = action.payload;

      // console.log( 'CREATE_NODE', { nodeType, x, y } )

      const createPromise = new Promise( ( resolve ) => {
          import( `../components/nodes/${ nodeType }.js` ).then( ( module ) => {
            // get input specs
            const { inputs = [], params = [], settings = [] } = module;

            resolve( { inputs, params, settings } );
          } );
      } );

      return from( createPromise ).pipe(
        mergeMap( result => [
          addNode( {
            nid, nodeType, x, y, inputs: [], outputs: [],
          } ),
          setNodeInputs( { nid, inputs: result.inputs } ),
          setNodeParams( { nid, params: result.params } ),
          setNodeSettings( { nid, settings: result.settings } ),
        ] )
      );
    } )
  ),
  [ CONNECT_NODE ]: ( action$: ActionsObservable<connectNode>, state$: StateObservable<STATE_TYPE> ) => action$.pipe(
    mergeMap( ( action: { payload: CONNECT_NODE_TYPE } ) => {
      const {
        fromNode, fromPin, toNode, toPin,
      } = action.payload;

      // console.log( 'CONNECT_NODE', { fromNode, fromPin, toNode, toPin } )

      return [
        addConnector( {
          fromNode, fromPin, toNode, toPin,
        } ),
        resonate( { nid: toNode } ),
      ];
    } )
  ),
  [ DISCONNECT_NODE ]: ( action$: ActionsObservable<disconnectNode>, state$: StateObservable<STATE_TYPE> ) => action$.pipe(
    mergeMap( ( action: { payload: DISCONNECT_NODE_TYPE } ) => {
      const {
        fromNode, fromPin, toNode, toPin,
      } = action.payload;

      return [
        removeConnector( {
          fromNode, fromPin, toNode, toPin,
        } ),
        recompute( { nid: toNode } ),
        resonate( { nid: toNode } ),
      ];
    } )
  ),
} );

export default nodes;
