// @flow

import { combineEpics } from 'redux-observable';

// import epics
import nodes from './nodes';
import computes from './computes';

// combine epics
const rootEpic = combineEpics(
  nodes,
  computes,
);

export default rootEpic;
