// @flow

import React from 'react';
import { connect } from 'react-redux';
import Loadable from 'react-loadable';
import isEqual from 'react-fast-compare';

import type {
  NODE_DATA_TYPE,
  NODE_META_ITEM_TYPE,
  NODE_INPUT_TYPE,
  NODE_SETTINGS_ITEM_TYPE,
  NODE_PROPERTIES_ITEM_TYPE,
} from '../../data/nodes';

type Props = {
  nid: string,
  dragging: boolean,

  nodeType: string,
  data: NODE_DATA_TYPE,
  meta: Array<NODE_META_ITEM_TYPE>,
  inputs: Array<NODE_INPUT_TYPE>,
  settings: Array<NODE_SETTINGS_ITEM_TYPE>,
  properties: Array<NODE_PROPERTIES_ITEM_TYPE>,
}

type State = {

}

class NodeContent extends React.Component<Props, State> {
  shouldComponentUpdate ( nextProps: Props, nextState: State ) {
    const {
      data, meta, inputs, settings, properties,
      dragging,
    } = this.props;

    if (
      data !== nextProps.data ||
      meta !== nextProps.meta ||
      !isEqual( inputs, nextProps.inputs ) ||
      !isEqual( settings, nextProps.settings ) ||
      !isEqual( properties, nextProps.properties )
    ) return true;

    if (
      ( !dragging && nextProps.dragging ) ||
      ( dragging === nextProps.dragging )
    ) return false;

    return false;
  }

  render () {
    const { nid } = this.props;
    const {
      nodeType, inputs, data, meta, settings, properties,
    } = this.props;

    const LoadableComponent = Loadable( {
      loader: () => import( `../nodes/${ nodeType }.js` ),
      loading () {
        return (
          <div>
            loading...
          </div>
        );
      },
      delay: 300,
    } );

    return (
      <LoadableComponent
        key={ `node-content-${ nid }` }
        inputs={ inputs }
        data={ data }
        meta={ meta }
        settings={ settings }
        properties={ properties }
      />
    );
  }
}

function select ( state, props ) {
  const { nid } = props;

  // TODO: reselect computations below

  // get current node
  const node = state.nodes.find( _node => _node.nid === nid );

  // get inputs from upstream nodes into current node
  const inputs = state.connectors
    .filter( connector => connector.toNode === nid )
    .reduce( ( acc, connector ) => {
      const fromNode = state.nodes.find( _node => _node.nid === connector.fromNode );
      acc[ connector.toPin ] = {
        fromPin: connector.fromPin,
        fromPinIndex: fromNode.meta.findIndex( m => m.name === connector.fromPin ),
        data: fromNode.data,
        meta: fromNode.meta,
      };
      return acc;
    }, {} );

  // transform settings to key-value object
  const settingValues = node.settings.reduce( ( acc, setting ) => {
    acc[ setting.name ] = setting.value; return acc;
  }, {} );

  return {
    nodeType: node.nodeType,
    data: node.data,
    meta: node.meta,
    inputs,
    settings: settingValues,
    properties: node.properties,
  };
}

function actions ( dispatch, props ) {
  return {};
}

export default connect( select, actions )( NodeContent );
