// @flow

import { combineReducers } from 'redux';

// import reducers
import nodes from './nodes';
import connectors from './connectors';
import toolbarItems from './toolbarItems';
import app from './app';

// combine reducers
const rootReducer = combineReducers( {
  nodes,
  connectors,
  toolbarItems,
  app,
} );

export default rootReducer;
