import { createSelector } from 'reselect';

export const getNodes = state => state.nodes;

export const makeGetNodeByNid = ( nid ) => {
  const getNid = () => nid;

  return createSelector(
    [ getNodes, getNid ],
    ( nodes, _nid ) => nodes.find( node => node.nid === _nid )
  );
};
