// @flow

import { createNode } from '../actions/nodes';
import { resetCanvas } from '../actions/app';

export type TOOLBAR_ITEM_TYPE =
  {| name: string, action: Function, payload: Object |} |
  {| submenu: string, items: Array<TOOLBAR_ITEM_TYPE>, align?: string |};

export type TOOLBARITEMS_STATE_TYPE = Array<TOOLBAR_ITEM_TYPE>;

const toolbarItems: TOOLBARITEMS_STATE_TYPE = [
  // {
  //   submenu: 'Data',
  //   items: [
  //     {
  //       name: 'CSV File',
  //       action: createNode,
  //       payload: { nodeType: 'data/CsvFileNode', x: 'center', y: 'center' },
  //     },
  //   ],
  // },
  {
    submenu: 'Transforms',
    items: [
      {
        submenu: 'Math',
        items: [
          {
            name: 'Operation with Value',
            action: createNode,
            payload: { nodeType: 'math/OperationNode', x: 'center', y: 'center' },
          },
          {
            name: 'Operation with Operands',
            action: createNode,
            payload: { nodeType: 'math/OperandNode', x: 'center', y: 'center' },
          },
        ],
      },
      {
        submenu: 'Dates',
        items: [
          {
            name: 'Date to Timestamp',
            action: createNode,
            payload: { nodeType: 'dates/DateToTimestampNode', x: 'center', y: 'center' },
          },
        ],
      },
      {
        submenu: 'Coordinates',
        items: [
          {
            name: 'UKGrid to LatLong',
            action: createNode,
            payload: { nodeType: 'coordinates/UKGridToLatLongNode', x: 'center', y: 'center' },
          },
        ],
      },
    ],
  },
  {
    submenu: 'Aggregates',
    items: [
      {
        submenu: 'Group Categories',
        items: [
          {
            name: 'Group Category and Sum',
            action: createNode,
            payload: { nodeType: 'aggregates/SumGroupNode', x: 'center', y: 'center' },
          },
          {
            name: 'Group Category and Count',
            action: createNode,
            payload: { nodeType: 'aggregates/CountGroupNode', x: 'center', y: 'center' },
          },
          {
            name: 'Group Category and Average',
            action: createNode,
            payload: { nodeType: 'aggregates/AverageGroupNode', x: 'center', y: 'center' },
          },
        ],
      },
      {
        submenu: 'Group Intervals',
        items: [
          {
            name: 'Group Interval and Sum',
            action: createNode,
            payload: { nodeType: 'aggregates/SumIntervalNode', x: 'center', y: 'center' },
          },
          {
            name: 'Group Interval and Count',
            action: createNode,
            payload: { nodeType: 'aggregates/CountIntervalNode', x: 'center', y: 'center' },
          },
          {
            name: 'Group Interval and Average',
            action: createNode,
            payload: { nodeType: 'aggregates/AverageIntervalNode', x: 'center', y: 'center' },
          },
        ],
      },
    ],
  },
  {
    submenu: 'Joins',
    items: [
      {
        name: 'Join By Id',
        action: createNode,
        payload: { nodeType: 'joins/JoinByIdNode', x: 'center', y: 'center' },
      },
    ],
  },
  {
    submenu: 'Utilities',
    items: [
      {
        name: 'View',
        action: createNode,
        payload: { nodeType: 'utils/ViewNode', x: 'center', y: 'center' },
      },
    ],
  },
  {
    submenu: 'Charts',
    items: [
      {
        name: 'Bar Chart',
        action: createNode,
        payload: { nodeType: 'charts/BarChartNode', x: 'center', y: 'center' },
      },
      {
        name: 'Radar Chart',
        action: createNode,
        payload: { nodeType: 'charts/RadarChartNode', x: 'center', y: 'center' },
      },
      {
        name: 'Scatter Chart',
        action: createNode,
        payload: { nodeType: 'charts/ScatterChartNode', x: 'center', y: 'center' },
      },
      {
        name: 'Line Chart',
        action: createNode,
        payload: { nodeType: 'charts/LineChartNode', x: 'center', y: 'center' },
      },
      {
        name: 'Time Series Chart',
        action: createNode,
        payload: { nodeType: 'charts/TimeSeriesNode', x: 'center', y: 'center' },
      },
      {
        name: 'Pie Chart',
        action: createNode,
        payload: { nodeType: 'charts/PieChartNode', x: 'center', y: 'center' },
      },
      {
        name: 'Funnel Chart',
        action: createNode,
        payload: { nodeType: 'charts/FunnelChartNode', x: 'center', y: 'center' },
      },
      // {
      //   name: 'Smu Scatter Chart',
      //   action: createNode,
      //   payload: { nodeType: 'charts/SmuChartNode', x: 'center', y: 'center' },
      // },
    ],
  },
  {
    submenu: 'Maps',
    items: [
      {
        name: 'Map with Markers',
        action: createNode,
        payload: { nodeType: 'maps/MarkerMapNode', x: 'center', y: 'center' },
      },
      {
        name: 'Map with Clustered Markers',
        action: createNode,
        payload: { nodeType: 'maps/ClusterMapNode', x: 'center', y: 'center' },
      },
      {
        name: 'Map with Heatmap',
        action: createNode,
        payload: { nodeType: 'maps/HeatmapMapNode', x: 'center', y: 'center' },
      },
    ],
  },
  {
    submenu: 'Reset',
    align: 'right',
    items: [
      {
        name: 'Clear Workspace',
        action: resetCanvas,
        payload: { },
      },
    ],
  },

];

export default toolbarItems;
