import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'react-fast-compare';

import { getNodes } from './nodes';

const createEqSelector = createSelectorCreator( defaultMemoize, isEqual );

export const getApp = state => state.app;

export const getSelectedNodeNid = createSelector(
  [ getApp ],
  app => app.selectedNodeNid
);

export const getSidePanelCollapsed = createSelector(
  [ getApp ],
  app => app.sidePanelCollapsed
);

export const getSelectedNode = createSelector(
  [ getSelectedNodeNid, getNodes ],
  ( nid, nodes ) => ( nodes.find( node => node.nid === nid ) || {} )
);

export const getSelectedNodeNodeType = createSelector(
  [ getSelectedNode ],
  node => node.nodeType
);

export const getSelectedNodeSettings = createSelector(
  [ getSelectedNode ],
  node => ( node.settings || [] )
);

export const getSelectedNodeProperties = createSelector(
  [ getSelectedNode ],
  node => ( node.properties || [] )
);

export const getSelectedNodeSettingNames = createEqSelector(
  [ getSelectedNodeSettings ],
  settings => settings.map( setting => setting.name )
);

export const getSelectedNodePropertyNames = createEqSelector(
  [ getSelectedNodeProperties ],
  properties => properties.map( property => property.name )
);

export const makeGetSelectedNodeSettingBySettingName = ( settingName ) => {
  const getSettingName = () => settingName;
  return createSelector(
    [ getSelectedNodeSettings, getSettingName ],
    ( settings, _settingName ) => settings.find( setting => setting.name === _settingName )
  );
};

export const makeGetSelectedNodePropertyByPropertyName = ( propertyName ) => {
  const getPropertyName = () => propertyName;
  return createSelector(
    [ getSelectedNodeProperties, getPropertyName ],
    ( properties, _propertyName ) => properties.find( property => property.name === _propertyName )
  );
};
