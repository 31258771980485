// @flow

import React from 'react';
import { connect } from 'react-redux';

import { Form } from 'antd';

import Control from '../Control';

import { setNodeProperty } from '../../actions/nodes';

import { makeGetSelectedNodePropertyByPropertyName, getSelectedNodeNid } from '../../selectors/app';

import type { NODE_PROPERTIES_ITEM_TYPE } from '../../data/nodes';

type Props = {
  dispatch: Function,
  nid: string,
  property: NODE_PROPERTIES_ITEM_TYPE,
  dispatchSetNodeProperty: ( value: number | string | boolean ) => void,
}

function PropertyItem ( props: Props ) {
  const { nid, property, dispatch } = props;

  function dispatchSetNodeProperty ( value ) {
    dispatch( setNodeProperty( { nid, name: property.name, value } ) );
  }

  return (
    <Form.Item
      label={ property.name }
      colon={ false }
      labelCol={ { xs: { span: 8 } } }
      wrapperCol={ { xs: { span: 16 } } }
    >
      <Control
        type={ property.type }
        onChange={ dispatchSetNodeProperty }
        value={ property.value }
        key={ `property-control-${ property.name }` }
        { ...property }
      />
    </Form.Item>
  );
}

function select ( state, props ) {
  const getSelectedNodePropertyByPropertyName =
    makeGetSelectedNodePropertyByPropertyName( props.name );

  return ( _state, _props ) => ( {
    nid: getSelectedNodeNid( _state ),
    property: getSelectedNodePropertyByPropertyName( _state ),
  } );
}

function actions ( dispatch, props ) {
  return {
    dispatch,
  };
}

export default connect( select, actions )( PropertyItem );
