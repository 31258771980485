// @flow

import * as L from 'partial.lenses';
import cr from './cr';

import { ADD_CONNECTOR, REMOVE_CONNECTOR } from '../actions/connectors';
import { RESET_CANVAS } from '../actions/app';

import type { CONNECTORS_STATE_TYPE } from '../data/connectors';
import type { RESET_CANVAS_TYPE } from '../actions/app';

import type {
  ADD_CONNECTOR_TYPE,
  REMOVE_CONNECTOR_TYPE,
} from '../actions/connectors';

type ACTION_TYPE<T> = {
  type: string,
  payload: T
}

/* eslint max-len: ["error", { "code": 150 }] */

const connectors = cr( [], {

  [ ADD_CONNECTOR ]: ( connectorsState: CONNECTORS_STATE_TYPE, action: ACTION_TYPE<ADD_CONNECTOR_TYPE> ): CONNECTORS_STATE_TYPE => {
    const {
      fromNode, fromPin, toNode, toPin,
    } = action.payload;

    return L.set(
      L.append,
      {
        fromNode,
        fromPin,
        toNode,
        toPin,
      },
      connectorsState
    );
  },

  [ REMOVE_CONNECTOR ]: ( connectorsState: CONNECTORS_STATE_TYPE, action: ACTION_TYPE<REMOVE_CONNECTOR_TYPE> ): CONNECTORS_STATE_TYPE => {
    const {
      fromNode, fromPin, toNode, toPin,
    } = action.payload;

    return L.set(
      L.find( connector => connector.fromNode === fromNode &&
        connector.fromPin === fromPin &&
        connector.toNode === toNode &&
        connector.toPin === toPin ),
      undefined,
      connectorsState
    );
  },
  [ RESET_CANVAS ]: ( connectorsState: CONNECTORS_STATE_TYPE, action: ACTION_TYPE<RESET_CANVAS_TYPE> ): CONNECTORS_STATE_TYPE => (
    []
  ),
} );

export default connectors;
