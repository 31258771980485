// @flow

import React from 'react';

import Loadable from 'react-loadable';

export const CONTROL_COMPONENTS = {
  boolean: 'BooleanControl',
  string: 'StringControl',
  number: 'NumberControl',
  color: 'ColorControl',
  select: 'SelectControl',
  buttongroup: 'ButtonGroupControl',
};

type Props = {
  type: string,
  onChange: Function,
  value: boolean | number | string,
}

type State = {

}

class Control extends React.Component<Props, State> {
  constructor ( props: Props ) {
    super( props );

    const { type = 'string' } = props;

    this.controlComponent = Loadable( {
      loader: () => import( `./${ CONTROL_COMPONENTS[ type ] }.js` ),
      loading () {
        return (
          <div>
            loading...
          </div>
        );
      },
      delay: 300,
    } );
  }

  shouldComponentUpdate ( nextProps: Props, nextState: State ) {
    const { type, onChange, value } = this.props;
    return (
      type !== nextProps.type ||
      onChange !== nextProps.onChange ||
      value !== nextProps.value
    );
  }

  controlComponent: React$ComponentType<*>;

  render () {
    const { onChange = () => {}, value, ...otherProps } = this.props;

    const ControlComponent = this.controlComponent;

    return (
      <ControlComponent
        onChange={ onChange }
        value={ value }
        { ...otherProps }
      />
    );
  }
}

export default Control;
