// @flow

import React from 'react';

import type { NODE_INPUT_TYPE } from '../../data/nodes';

type Props = {
  item: NODE_INPUT_TYPE,
  index: number,
  onMouseUp: ( i: number ) => void,
}

type State = {
  hover: boolean
}

export default class NodeInputListItem extends React.PureComponent<Props, State> {
  state = {
    hover: false,
  }

  onMouseUp = ( e: SyntheticMouseEvent<> ) => {
    e.stopPropagation();
    e.preventDefault();

    const { onMouseUp, index } = this.props;
    onMouseUp( index );
  }

  onMouseOver = () => {
    this.setState( { hover: true } );
  }

  onMouseOut = () => {
    this.setState( { hover: false } );
  }

  noop = ( e: SyntheticMouseEvent<> ) => {
    e.stopPropagation();
    e.preventDefault();
  }

  render () {
    const { item: { name } } = this.props;
    const { hover } = this.state;

    return (
      <li>
        <a
          onClick={ this.noop }
          onMouseUp={ this.onMouseUp }
          href="#"
        >
          <i
            className={ hover ? 'fa fa-circle-o hover' : 'fa fa-circle-o' }
            onMouseOver={ this.onMouseOver }
            onMouseOut={ this.onMouseOut }
          />
          {name}
        </a>
      </li>
    );
  }
}
