// @flow

import cuid from 'cuid';

import type {
  NODE_DATA_TYPE,
  NODE_META_ITEM_TYPE,
  NODE_PARAMS_ITEM_TYPE,
  NODE_SETTINGS_ITEM_TYPE,
  NODE_PROPERTIES_ITEM_TYPE,
  NODE_INPUT_TYPE, NODE_OUTPUT_TYPE,
} from '../data/nodes';

/* eslint-disable no-multi-spaces, max-len */

// sync actions

export const ADD_NODE               = 'ADD_NODE';
export type  ADD_NODE_TYPE          = { nid: string, nodeType: string, x: number | string, y: number | string, inputs: Array<NODE_INPUT_TYPE>, outputs: Array<NODE_OUTPUT_TYPE> };
export const addNode                = ( payload: ADD_NODE_TYPE ) => ( { type: ADD_NODE, payload } );

export const REMOVE_NODE            = 'REMOVE_NODE';
export type  REMOVE_NODE_TYPE       = { nid: string };
export const removeNode             = ( payload: REMOVE_NODE_TYPE ) => ( { type: REMOVE_NODE, payload } );

export const START_MOVE_NODE        = 'START_MOVE_NODE';
export type  START_MOVE_NODE_TYPE   = { nid: string };
export const startMoveNode          = ( payload: START_MOVE_NODE_TYPE ) => ( { type: START_MOVE_NODE, payload } );

export const STOP_MOVE_NODE         = 'STOP_MOVE_NODE';
export type  STOP_MOVE_NODE_TYPE    = { nid: string, x: number, y: number };
export const stopMoveNode           = ( payload: STOP_MOVE_NODE_TYPE ) => ( { type: STOP_MOVE_NODE, payload } );

export const MOVE_NODE              = 'MOVE_NODE';
export type  MOVE_NODE_TYPE         = { nid: string, x: number, y: number };
export const moveNode               = ( payload: MOVE_NODE_TYPE ) => ( { type: MOVE_NODE, payload } );

export const RENAME_NODE            = 'RENAME_NODE';
export type  RENAME_NODE_TYPE       = { nid: string, name: string };
export const renameNode             = ( payload: RENAME_NODE_TYPE ) => ( { type: RENAME_NODE, payload } );

export const SET_NODE_DATA          = 'SET_NODE_DATA';
export type  SET_NODE_DATA_TYPE     = { nid: string, data: NODE_DATA_TYPE, meta: Array<NODE_META_ITEM_TYPE> };
export const setNodeData            = ( payload: SET_NODE_DATA_TYPE ) => ( { type: SET_NODE_DATA, payload } );

export const SET_NODE_PARAMS        = 'SET_NODE_PARAMS';
export type  SET_NODE_PARAMS_TYPE   = { nid: string, params: Array<NODE_PARAMS_ITEM_TYPE> };
export const setNodeParams          = ( payload: SET_NODE_PARAMS_TYPE ) => ( { type: SET_NODE_PARAMS, payload } );

export const SET_NODE_SETTINGS      = 'SET_NODE_SETTINGS';
export type  SET_NODE_SETTINGS_TYPE = { nid: string, settings: Array<NODE_SETTINGS_ITEM_TYPE> };
export const setNodeSettings        = ( payload: SET_NODE_SETTINGS_TYPE ) => ( { type: SET_NODE_SETTINGS, payload } );

export const SET_NODE_SETTING       = 'SET_NODE_SETTING';
export type  SET_NODE_SETTING_TYPE  = { nid: string, name: string, value: number | string | boolean };
export const setNodeSetting         = ( payload: SET_NODE_SETTING_TYPE ) => ( { type: SET_NODE_SETTING, payload } );

export const SET_NODE_PROPERTIES    = 'SET_NODE_PROPERTIES';
export type  SET_NODE_PROPERTIES_TYPE   = { nid: string, properties: Array<NODE_PROPERTIES_ITEM_TYPE> };
export const setNodeProperties      = ( payload: SET_NODE_PROPERTIES_TYPE ) => ( { type: SET_NODE_PROPERTIES, payload } );

export const SET_NODE_PROPERTY      = 'SET_NODE_PROPERTY';
export type  SET_NODE_PROPERTY_TYPE = { nid: string, name: string, value: number | string | boolean };
export const setNodeProperty        = ( payload: SET_NODE_PROPERTY_TYPE ) => ( { type: SET_NODE_PROPERTY, payload } );

export const SET_NODE_INPUTS        = 'SET_NODE_INPUTS';
export type  SET_NODE_INPUTS_TYPE   = { nid: string, inputs: Array<NODE_INPUT_TYPE> };
export const setNodeInputs          = ( payload: SET_NODE_INPUTS_TYPE ) => ( { type: SET_NODE_INPUTS, payload } );

export const SET_NODE_OUTPUTS       = 'SET_NODE_OUTPUTS';
export type  SET_NODE_OUTPUTS_TYPE  = { nid: string, outputs: Array<NODE_OUTPUT_TYPE> };
export const setNodeOutputs         = ( payload: SET_NODE_OUTPUTS_TYPE ) => ( { type: SET_NODE_OUTPUTS, payload } );

// async epics

export const CREATE_NODE            = 'CREATE_NODE';
export type  CREATE_NODE_TYPE       = { nodeType: string, x: number, y: number };
export const createNode             = ( payload: CREATE_NODE_TYPE ) => ( { type: CREATE_NODE, payload, nid: cuid.slug() } );

export const CONNECT_NODE           = 'CONNECT_NODE';
export type  CONNECT_NODE_TYPE      = { fromNode: string, fromPin: string, toNode: string, toPin: string };
export const connectNode            = ( payload: CONNECT_NODE_TYPE ) => ( { type: CONNECT_NODE, payload } );

export const DISCONNECT_NODE        = 'DISCONNECT_NODE';
export type  DISCONNECT_NODE_TYPE   = { fromNode: string, fromPin: string, toNode: string, toPin: string };
export const disconnectNode         = ( payload: DISCONNECT_NODE_TYPE ) => ( { type: DISCONNECT_NODE, payload } );
