// @flow

import React from 'react';
import { connect } from 'react-redux';

import reactCSS from 'reactcss';
import onClickOutside from 'react-onclickoutside';
import Draggable from 'react-draggable';
import isEqual from 'react-fast-compare';

import NodeInputList from './NodeInputList';
import NodeOuputList from './NodeOutputList';
import NodeParams from './NodeParams';
import NodeContent from './NodeContent';

import { makeGetNodeByNid } from '../../selectors/nodes';

import type {
  NODE_INPUT_TYPE,
  NODE_OUTPUT_TYPE,
  NODE_PARAMS_ITEM_TYPE,
  NODE_PROPERTIES_ITEM_TYPE,
} from '../../data/nodes';

type Props = {
  nid: string,
  title: string,
  selected: boolean,
  onNodeStart: ( nid: string ) => void,
  onNodeStop: ( nid: string, x: number, y: number ) => void,
  onNodeMove: ( nid: string, x: number, y: number ) => void,
  onStartConnector: ( nid: string, outputIndex: number ) => void,
  onCompleteConnector: ( nid: string, inputIndex: number ) => void,
  onNodeSelect: ( nid: string ) => void,
  onNodeDeselect: ( nid: string ) => void,

  x: number,
  y: number,
  params: Array<NODE_PARAMS_ITEM_TYPE>,
  properties: Array<NODE_PROPERTIES_ITEM_TYPE>,
  inputs: Array<NODE_INPUT_TYPE>,
  outputs: Array<NODE_OUTPUT_TYPE>,
}

type State = {
  dragging: boolean,
  x: number,
  y: number,
}

class Node extends React.Component<Props, State> {
  constructor ( props: Props ) {
    super( props );

    this.state = {
      dragging: false, // moving the node around,
      x: props.x,
      y: props.y,
    };
  }

  shouldComponentUpdate ( nextProps: Props, nextState: State ) {
    const { dragging, x, y } = this.state;
    const {
      selected, inputs, outputs, properties,
    } = this.props;

    return (
      dragging !== nextState.dragging ||
      x !== nextState.x ||
      y !== nextState.y ||

      selected !== nextProps.selected ||
      outputs !== nextProps.outputs ||
      inputs !== nextProps.inputs ||
      !isEqual( properties, nextProps.properties )
    );
  }

  onStartConnector = ( index ) => {
    const { onStartConnector, nid } = this.props;
    onStartConnector( nid, index );
  }

  onCompleteConnector = ( index ) => {
    const { onCompleteConnector, nid } = this.props;
    onCompleteConnector( nid, index );
  }

  handleDragStart = ( event, ui ) => {
    const { onNodeStart, nid } = this.props;
    onNodeStart( nid );
    this.setState( { dragging: true, x: ui.x, y: ui.y } );
  }

  handleDragStop = ( event, ui ) => {
    const { onNodeStop, nid } = this.props;
    onNodeStop( nid, ui.x, ui.y );
    this.setState( { dragging: false, x: ui.x, y: ui.y } );
  }

  handleDrag = ( event, ui ) => {
    const { onNodeMove, nid } = this.props;
    onNodeMove( nid, ui.x, ui.y );
    this.setState( { x: ui.x, y: ui.y } );
  }

  handleHeaderDoubleClick = ( e ) => {
    const { onNodeDeselect, onNodeSelect, nid } = this.props;
    onNodeDeselect( nid );
    setTimeout( () => onNodeSelect( nid ), 200 );
  }

  handleClickOutside = ( e ) => {
    const { selected, onNodeDeselect, nid } = this.props;
    const { target: { classList: targetClasses } } = e;

    if ( targetClasses.contains( 'svg-component' ) && selected ) {
      onNodeDeselect( nid );
    }
  }

  render () {
    const {
      nid, title, selected,
      params, properties, inputs, outputs,
    } = this.props;
    const { dragging, x, y } = this.state;

    const property$Size = properties.find( property => property.name === 'Size' ) || { value: 'mini' };

    const hasParams = !!params.length;

    const styles = reactCSS( {
      default: {
        node: {
          zIndex: 1000,
          width: property$Size.value === 'maxi' ? '520px' : '220px',
        },
      },
    } );

    return (
      <div>
        <Draggable
          position={ { x, y } }
          handle=".node-header"
          onStart={ this.handleDragStart }
          onStop={ this.handleDragStop }
          onDrag={ this.handleDrag }
        >
          <section className={ `node${ selected ? ' selected' : '' }` } style={ styles.node }>
            <header className="node-header" onDoubleClick={ this.handleHeaderDoubleClick }>
              <span className="node-title">
                { title }
              </span>
            </header>
            <div className="node-body">
              <div className="node-connectors">
                <NodeInputList
                  items={ inputs }
                  onCompleteConnector={ this.onCompleteConnector }
                />
                <NodeOuputList
                  items={ outputs }
                  onStartConnector={ this.onStartConnector }
                />
              </div>

              <div className="node-content">
                { hasParams && <NodeParams nid={ nid } /> }
                <NodeContent nid={ nid } dragging={ dragging } />
              </div>
            </div>
          </section>
        </Draggable>
      </div>
    );
  }
}

const clickOutsideConfig = {
  excludeScrollbar: true,
};

function select ( state, props ) {
  const getNodeByNid = makeGetNodeByNid( props.nid );

  return ( _state, _props ) => {
    const node = getNodeByNid( _state );

    return {
      x: node.x,
      y: node.y,
      params: node.params,
      properties: node.properties,
      inputs: node.inputs,
      outputs: node.outputs,
    };
  };
}

function actions ( dispatch, props ) {
  return {

  };
}

export default connect( select, actions )( onClickOutside( Node, clickOutsideConfig ) );
