// @flow

import React from 'react';
import { connect } from 'react-redux';

import Node from './Node';
import Spline from './Spline';
import NewSpline from './NewSpline';
import SVGComponent from './SVGComponent';

import {
  startMoveNode, stopMoveNode, moveNode,
  connectNode, disconnectNode,
} from '../../actions/nodes';
import { createNodeAndLoadData } from '../../actions/computes';
import {
  openSidePanel, closeSidePanel, selectNode, deselectNode,
} from '../../actions/app';

import type { APP_STATE_TYPE } from '../../data/app';
import type { NODE_TYPE } from '../../data/nodes';
import type { CONNECTOR_TYPE } from '../../data/connectors';

type Props = {
  scrollX: number,
  scrollY: number,

  app: APP_STATE_TYPE,
  nodes: Array<NODE_TYPE>,
  connectors: Array<CONNECTOR_TYPE>,

  dispatchStartMoveNode: ( nid: string ) => void,
  dispatchStopMoveNode: ( nid: string, x: number, y: number ) => void,
  dispatchMoveNode: ( nid: string, x: number, y: number ) => void,
  dispatchConnectNode: (
    fromNode: string, fromPin: string, toNode: string, toPin: string
  ) => void,
  dispatchDisconnectNode: (
    fromNode: string, fromPin: string, toNode: string, toPin: string
  ) => void,
  dispatchSelectNode: ( nid: string ) => void,
  dispatchDeselectNode: () => void,
  dispatchOpenSidePanel: () => void,
  dispatchCloseSidePanel: () => void,
  dispatchCreateNodeAndLoadData: (
    nodeType: string, x: number, y: number, file: File
  ) => void,
}

type State = {
  dragging: boolean,
  sourceNid: ?string,
  sourceOutput: ?string,
  svgRect: { left: number, top: number },
}

class NodeGraph extends React.Component<Props, State> {
  constructor ( props ) {
    super( props );

    this.state = {
      sourceNid: null, // for dragging
      sourceOutput: null,
      dragging: false,
      svgRect: {
        left: 0,
        top: 0,
      },
    };

    this.canvas = React.createRef();
    this.svg = React.createRef();
  }

  componentDidMount () {
    window.addEventListener( 'resize', this.handleResize );

    this.handleResize();
  }

  shouldComponentUpdate ( nextProps, nextState ) {
    const { app, nodes, connectors } = this.props;
    const { dragging } = this.state;

    return (
      app.selectedNodeNid !== nextProps.app.selectedNodeNid ||
      nodes !== nextProps.nodes ||
      connectors !== nextProps.connectors ||
      dragging !== nextState.dragging
    );
  }

  componentWillUnmount () {
    window.removeEventListener( 'resize', this.handleResize );
  }

  onMouseUp = ( e: MouseEvent ) => {
    this.setState( {
      dragging: false,
    } );
  }

  handleNodeStart = ( nid: string ) => {
    const { dispatchStartMoveNode } = this.props;
    dispatchStartMoveNode( nid );
  }

  handleNodeStop = ( nid: string, x: number, y: number ) => {
    const { dispatchStopMoveNode } = this.props;
    dispatchStopMoveNode( nid, x, y );
  }

  handleNodeMove = ( nid: string, x: number, y: number ) => {
    const { dispatchMoveNode } = this.props;
    dispatchMoveNode( nid, x, y );
  }

  handleConnectorStart = ( nid: string, outputIndex: number ) => {
    const { nodes } = this.props;
    const node = nodes.find( _node => _node.nid === nid );
    if ( node ) {
      this.setState( {
        dragging: true,
        sourceNid: nid,
        sourceOutput: node.outputs[ outputIndex ].name,
      } );
    }
  }

  handleConnectorEnd = ( nid: string, inputIndex: number ) => {
    const { dragging, sourceNid, sourceOutput } = this.state;
    const { nodes, dispatchConnectNode } = this.props;

    if ( dragging ) {
      const fromNode = sourceNid;
      const fromPin = sourceOutput;
      const toNode = nid;
      const node = nodes.find( _node => _node.nid === nid );

      if ( fromNode && fromPin && node ) {
        const toPin = node.inputs[ inputIndex ].name;

        dispatchConnectNode( fromNode, fromPin, toNode, toPin );
      }
    }
    this.setState( {
      dragging: false,
    } );
  }

  handleRemoveConnector = ( connector ) => {
    const { dispatchDisconnectNode } = this.props;
    const {
      fromNode, fromPin, toNode, toPin,
    } = connector;
    dispatchDisconnectNode( fromNode, fromPin, toNode, toPin );
  }

  handleNodeSelect = ( nid: string ) => {
    const { dispatchSelectNode, dispatchOpenSidePanel } = this.props;
    dispatchSelectNode( nid );
    dispatchOpenSidePanel();
  }

  handleNodeDeselect = ( nid: string ) => {
    const { dispatchDeselectNode, dispatchCloseSidePanel } = this.props;
    dispatchDeselectNode();
    dispatchCloseSidePanel();
  }

  handleResize = ( e: ?Event ) => {
    const svg = this.svg.current;
    if ( svg ) {
      const svgElement = svg.svgElement.current;
      if ( svgElement ) {
        const clientRect = svgElement.getBoundingClientRect();
        this.setState( {
          svgRect: {
            left: clientRect.left,
            top: clientRect.top,
          },
        } );
      }
    }
  }

  handleFileDragEnter = ( e: SyntheticDragEvent<> ) => {
    e.stopPropagation();
    e.preventDefault();
  }

  handleFileDragLeave = ( e: SyntheticDragEvent<> ) => {
    e.stopPropagation();
    e.preventDefault();
  }

  handleFileDragOver = ( e: SyntheticDragEvent<> ) => {
    e.stopPropagation();
    e.preventDefault();
  }

  handleFileDrop = ( e: SyntheticDragEvent<> ) => {
    const { dispatchCreateNodeAndLoadData } = this.props;

    e.stopPropagation();
    e.preventDefault();

    const nativeE = e.nativeEvent;

    if ( nativeE instanceof DragEvent ) {
      // get dragged files
      const { dataTransfer: { files } } = e;

      // map each dropped file
      Array.from( files ).forEach( ( file, i ) => {
        if ( file.type === 'text/csv' ) {
          dispatchCreateNodeAndLoadData(
            'data/CsvFileNode',
            nativeE.offsetX + 30 * i,
            nativeE.offsetY + 30 * i,
            file
          );
        } else {
          console.error( `File type ${ file.type } unsupported for now` );
        }
      } );
    }
  }

  canvas: { current: null | HTMLDivElement };

  svg: { current: null | React$ElementRef<typeof SVGComponent> };

  renderNodes () {
    const { nodes, app } = this.props;

    return nodes.map( ( node, i ) => (
      <Node
        index={ i }
        nid={ node.nid }
        title={ node.nodeType }
        selected={ node.nid === app.selectedNodeNid }
        key={ `node-${ node.nid }` }

        onNodeStart={ this.handleNodeStart }
        onNodeStop={ this.handleNodeStop }
        onNodeMove={ this.handleNodeMove }

        onStartConnector={ this.handleConnectorStart }
        onCompleteConnector={ this.handleConnectorEnd }

        onNodeSelect={ this.handleNodeSelect }
        onNodeDeselect={ this.handleNodeDeselect }
      />
    ) );
  }

  renderConnectors () {
    const { connectors, nodes } = this.props;
    const { svgRect } = this.state;

    return connectors.map( ( connector ) => {
      const fromNode = nodes.find( node => node.nid === connector.fromNode );
      const toNode = nodes.find( node => node.nid === connector.toNode );

      if ( fromNode && toNode ) {
        const fromNodePinIndex =
          fromNode.outputs.findIndex( field => field.name === connector.fromPin );
        const toNodePinIndex =
          toNode.inputs.findIndex( field => field.name === connector.toPin );

        const splineStart = {
          x: fromNode.x + 166 + 40,
          y: fromNode.y + 45 + 22 * fromNodePinIndex,
        };

        const splineEnd = {
          x: toNode.x + 15,
          y: toNode.y + 43 + 20 * toNodePinIndex,
        };

        return (
          <Spline
            start={ splineStart }
            end={ splineEnd }
            svgRect={ svgRect }
            key={ `spline-${ fromNode.nid }-${ fromNodePinIndex }-${ toNode.nid }-${ toNodePinIndex }` }

            onRemove={ () => this.handleRemoveConnector( connector ) }
          />
        );
      }

      return null;
    } );
  }

  renderNewConnector () {
    // appears only whilst dragging spline

    const { nodes, scrollX, scrollY } = this.props;
    const { svgRect } = this.state;
    const { sourceNid, sourceOutput } = this.state;

    const sourceNode = nodes.find( node => node.nid === sourceNid );

    if ( sourceNode ) {
      const connectorStart = {
        x: sourceNode.x + 166 + 40,
        y: sourceNode.y + 45 +
          ( 22 * sourceNode.outputs.findIndex( field => field.name === sourceOutput ) ),
      };

      return (
        <NewSpline
          start={ connectorStart }
          svgRect={ svgRect }
          scrollX={ scrollX }
          scrollY={ scrollY }
        />
      );
    }

    return null;
  }

  render () {
    const { dragging } = this.state;

    return (
      <div
        className={ dragging ? 'dragging' : '' }
        ref={ this.canvas }
        onMouseUp={ this.onMouseUp }
        onDragEnter={ this.handleFileDragEnter }
        onDragLeave={ this.handleFileDragLeave }
        onDragOver={ this.handleFileDragOver }
        onDrop={ this.handleFileDrop }
        role="presentation"
      >

        <div>
          { this.renderNodes() }
        </div>

        <SVGComponent className="svg-component" ref={ this.svg }>
          { this.renderConnectors() }

          { dragging && this.renderNewConnector() }
        </SVGComponent>

      </div>
    );
  }
}

function select ( state ) {
  return {
    nodes: state.nodes,
    connectors: state.connectors,
    app: state.app,
  };
}

function actions ( dispatch, props ) {
  return {
    dispatchStartMoveNode: ( nid: string ) => dispatch(
      startMoveNode( { nid } )
    ),
    dispatchStopMoveNode: ( nid: string, x: number, y: number ) => dispatch(
      stopMoveNode( { nid, x, y } )
    ),
    dispatchMoveNode: ( nid: string, x: number, y: number ) => dispatch(
      moveNode( { nid, x, y } )
    ),
    dispatchConnectNode (
      fromNode: string,
      fromPin: string,
      toNode: string,
      toPin: string
    ) {
      dispatch(
        connectNode( {
          fromNode, fromPin, toNode, toPin,
        } )
      );
    },
    dispatchDisconnectNode (
      fromNode: string,
      fromPin: string,
      toNode: string,
      toPin: string
    ) {
      dispatch(
        disconnectNode( {
          fromNode, fromPin, toNode, toPin,
        } )
      );
    },
    dispatchSelectNode: ( nid: string ) => dispatch(
      selectNode( { nid } )
    ),
    dispatchDeselectNode: () => dispatch(
      deselectNode( {} )
    ),
    dispatchOpenSidePanel: () => dispatch(
      openSidePanel( {} )
    ),
    dispatchCloseSidePanel: () => dispatch(
      closeSidePanel( {} )
    ),
    dispatchCreateNodeAndLoadData (
      nodeType: string,
      x: number,
      y: number,
      file: File
    ) {
      dispatch(
        createNodeAndLoadData( {
          nodeType, x, y, file,
        } )
      );
    },
  };
}

export default connect( select, actions )( NodeGraph );
