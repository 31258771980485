// @flow

import React from 'react';

import NodeInputListItem from './NodeInputListItem';

import type { NODE_INPUT_TYPE } from '../../data/nodes';

type Props = {
  items: Array<NODE_INPUT_TYPE>,
  onCompleteConnector: ( index: number ) => void,
}

type State = {

}

export default class NodeInputList extends React.PureComponent<Props, State> {
  onMouseUp ( index: number ) {
    const { onCompleteConnector } = this.props;
    onCompleteConnector( index );
  }

  render () {
    const { items } = this.props;

    return (
      <div className="nodeInputWrapper">
        <ul className="nodeInputList">
          { items.map( ( item, index ) => (
            <NodeInputListItem
              onMouseUp={ i => this.onMouseUp( i ) }
              key={ `node-input-list-item-${ item.name }` }
              index={ index }
              item={ item }
            />
          ) ) }
        </ul>
      </div>
    );
  }
}
