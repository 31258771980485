// @flow

import cuid from 'cuid';

import type { NODE_PARAMS_ITEM_TYPE } from '../data/nodes';

/* eslint-disable no-multi-spaces, max-len */
export const CREATE_NODE_AND_LOAD_DATA      = 'CREATE_NODE_AND_LOAD_DATA';
export type  CREATE_NODE_AND_LOAD_DATA_TYPE = { nodeType: string, x: number | string, y: number | string, file: File };
export const createNodeAndLoadData          = ( payload: CREATE_NODE_AND_LOAD_DATA_TYPE ) => ( { type: CREATE_NODE_AND_LOAD_DATA, payload, nid: cuid.slug() } );

export const LOAD_DATA              = 'LOAD_DATA';
export type  LOAD_DATA_TYPE         = { nid: string, file: File };
export const loadData               = ( payload: LOAD_DATA_TYPE ) => ( { type: LOAD_DATA, payload } );

export const PARAM_CHANGE           = 'PARAM_CHANGE';
export type  PARAM_CHANGE_TYPE      = { nid: string, params: Array<NODE_PARAMS_ITEM_TYPE> };
export const paramChange            = ( payload: PARAM_CHANGE_TYPE ) => ( { type: PARAM_CHANGE, payload } );

export const RESONATE               = 'RESONATE';
export type  RESONATE_TYPE          = { nid: string };
export const resonate               = ( payload: RESONATE_TYPE ) => ( { type: RESONATE, payload } );

export const RECOMPUTE              = 'RECOMPUTE';
export type  RECOMPUTE_TYPE         = { nid: string };
export const recompute              = ( payload: RECOMPUTE_TYPE ) => ( { type: RECOMPUTE, payload } );
