// @flow

import * as L from 'partial.lenses';
import cr from './cr';

import {
  ADD_NODE, MOVE_NODE, SET_NODE_DATA, SET_NODE_PARAMS,
  SET_NODE_SETTINGS, SET_NODE_SETTING,
  SET_NODE_PROPERTIES, SET_NODE_PROPERTY,
  SET_NODE_INPUTS, SET_NODE_OUTPUTS,
} from '../actions/nodes';
import { RESET_CANVAS } from '../actions/app';
import {
  DEFAULT_NODE_PROPERTIES,
} from '../data/nodes';

import type { NODES_STATE_TYPE } from '../data/nodes';
import type { RESET_CANVAS_TYPE } from '../actions/app';
import type {
  MOVE_NODE_TYPE,
  ADD_NODE_TYPE,
  SET_NODE_DATA_TYPE,
  SET_NODE_PARAMS_TYPE,
  SET_NODE_SETTINGS_TYPE,
  SET_NODE_SETTING_TYPE,
  SET_NODE_PROPERTIES_TYPE,
  SET_NODE_PROPERTY_TYPE,
  SET_NODE_INPUTS_TYPE,
  SET_NODE_OUTPUTS_TYPE,
} from '../actions/nodes';

type ACTION_TYPE<T> = {
  type: string,
  payload: T
}

/* eslint max-len: ["error", { "code": 150 }] */

const nodes = cr( [], {
  [ MOVE_NODE ]: ( nodesState: NODES_STATE_TYPE, action: ACTION_TYPE<MOVE_NODE_TYPE> ): NODES_STATE_TYPE => {
    const { nid, x, y } = action.payload;

    return L.assign(
      L.find( node => node.nid === nid ),
      { x, y },
      nodesState
    );
  },

  [ ADD_NODE ]: ( nodesState: NODES_STATE_TYPE, action: ACTION_TYPE<ADD_NODE_TYPE> ): NODES_STATE_TYPE => {
    const {
      nid, nodeType, inputs, outputs,
    } = action.payload;
    let { x, y } = action.payload;

    // console.log( 'ADD_NODE', { nodeType, x, y, inputs, outputs } )

    if ( x === 'left' ) x = 100;
    else if ( x === 'right' ) x = window.innerWidth - 400;
    else if ( !Number( x ) || x === 'center' ) x = window.innerWidth / 2 - 100;

    if ( y === 'top' ) y = 100;
    else if ( y === 'bottom' ) y = window.innerHeight - 400;
    else if ( !Number( y ) || y === 'center' ) y = window.innerHeight / 2 - 200;

    return L.set(
      L.append,
      {
        nid,
        data: [],
        meta: [],
        params: [],
        settings: [],
        properties: DEFAULT_NODE_PROPERTIES,
        nodeType,
        x,
        y,
        inputs,
        outputs,
      },
      nodesState
    );
  },

  [ SET_NODE_DATA ]: ( nodesState: NODES_STATE_TYPE, action: ACTION_TYPE<SET_NODE_DATA_TYPE> ): NODES_STATE_TYPE => {
    const { nid, data, meta } = action.payload;

    return L.assign(
      L.find( node => node.nid === nid ),
      { data, meta },
      nodesState
    );
  },

  [ SET_NODE_PARAMS ]: ( nodesState: NODES_STATE_TYPE, action: ACTION_TYPE<SET_NODE_PARAMS_TYPE> ): NODES_STATE_TYPE => {
    const { nid, params } = action.payload;

    return L.assign(
      L.find( node => node.nid === nid ),
      { params },
      nodesState
    );
  },

  [ SET_NODE_SETTINGS ]: ( nodesState: NODES_STATE_TYPE, action: ACTION_TYPE<SET_NODE_SETTINGS_TYPE> ): NODES_STATE_TYPE => {
    const { nid, settings } = action.payload;

    return L.assign(
      L.find( node => node.nid === nid ),
      { settings },
      nodesState
    );
  },

  [ SET_NODE_SETTING ]: ( nodesState: NODES_STATE_TYPE, action: ACTION_TYPE<SET_NODE_SETTING_TYPE> ): NODES_STATE_TYPE => {
    const { nid, name, value } = action.payload;

    return L.set(
      [
        L.find( node => node.nid === nid ),
        'settings',
        L.find( setting => setting.name === name ),
        'value',
      ],
      value,
      nodesState
    );
  },

  [ SET_NODE_PROPERTIES ]: ( nodesState: NODES_STATE_TYPE, action: ACTION_TYPE<SET_NODE_PROPERTIES_TYPE> ): NODES_STATE_TYPE => {
    const { nid, properties } = action.payload;

    return L.assign(
      L.find( node => node.nid === nid ),
      { properties },
      nodesState
    );
  },

  [ SET_NODE_PROPERTY ]: ( nodesState: NODES_STATE_TYPE, action: ACTION_TYPE<SET_NODE_PROPERTY_TYPE> ): NODES_STATE_TYPE => {
    const { nid, name, value } = action.payload;

    return L.set(
      [
        L.find( node => node.nid === nid ),
        'properties',
        L.find( property => property.name === name ),
        'value',
      ],
      value,
      nodesState
    );
  },

  [ SET_NODE_INPUTS ]: ( nodesState: NODES_STATE_TYPE, action: ACTION_TYPE<SET_NODE_INPUTS_TYPE> ): NODES_STATE_TYPE => {
    const { nid, inputs } = action.payload;

    // console.log( 'SET_NODE_INPUTS', { nid, inputs } )

    return L.assign(
      L.find( node => node.nid === nid ),
      { inputs },
      nodesState
    );
  },

  [ SET_NODE_OUTPUTS ]: ( nodesState: NODES_STATE_TYPE, action: ACTION_TYPE<SET_NODE_OUTPUTS_TYPE> ): NODES_STATE_TYPE => {
    const { nid, outputs } = action.payload;

    // console.log( 'SET_NODE_OUTPUTS', { nid, outputs } )

    return L.assign(
      L.find( node => node.nid === nid ),
      { outputs },
      nodesState
    );
  },
  [ RESET_CANVAS ]: ( connectorsState: NODES_STATE_TYPE, action: ACTION_TYPE<RESET_CANVAS_TYPE> ): NODES_STATE_TYPE => (
    []
  ),
} );

export default nodes;
