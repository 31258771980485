// @flow

import React from 'react';

import NodeOutputListItem from './NodeOutputListItem';

import type { NODE_OUTPUT_TYPE } from '../../data/nodes';

type Props = {
  items: Array<NODE_OUTPUT_TYPE>,
  onStartConnector: ( index: number ) => void,
}

type State = {

}

export default class NodeOutputList extends React.PureComponent<Props, State> {
  onMouseDown = ( index: number ) => {
    const { onStartConnector } = this.props;
    onStartConnector( index );
  }

  render () {
    const { items } = this.props;

    return (
      <div className="nodeOutputWrapper">
        <ul className="nodeOutputList">
          { items.map( ( item, index ) => (
            <NodeOutputListItem
              onMouseDown={ this.onMouseDown }
              key={ `node-output-list-item-${ item.name }` }
              index={ index }
              item={ item }
            />
          ) ) }
        </ul>
      </div>
    );
  }
}
