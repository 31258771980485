// @flow

export function bezierCurve (
  a: number, b: number,
  cp1x: number, cp1y: number, cp2x: number, cp2y: number,
  x: number, y: number
) {
  return `M${ a },${ b } C${ cp1x },${ cp1y } ${ cp2x },${ cp2y }  ${ x },${ y }`;
}

export function distance ( a: Array<number>, b: Array<number> ): number {
  return Math.sqrt(
    ( b[ 0 ] - a[ 0 ] ) * ( b[ 0 ] - a[ 0 ] ) +
    ( b[ 1 ] - a[ 1 ] ) * ( b[ 1 ] - a[ 1 ] )
  );
}
