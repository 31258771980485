// @flow

/* eslint-disable no-multi-spaces, max-len */
export const SELECT_NODE            = 'SELECT_NODE';
export type  SELECT_NODE_TYPE       = { nid: string };
export const selectNode             = ( payload: SELECT_NODE_TYPE ) => ( { type: SELECT_NODE, payload } );

export const DESELECT_NODE          = 'DESELECT_NODE';
export type  DESELECT_NODE_TYPE     = {};
export const deselectNode           = ( payload: DESELECT_NODE_TYPE ) => ( { type: DESELECT_NODE, payload } );

export const OPEN_SIDE_PANEL        = 'OPEN_SIDE_PANEL';
export type  OPEN_SIDE_PANEL_TYPE   = {};
export const openSidePanel          = ( payload: OPEN_SIDE_PANEL_TYPE ) => ( { type: OPEN_SIDE_PANEL, payload } );

export const CLOSE_SIDE_PANEL       = 'CLOSE_SIDE_PANEL';
export type  CLOSE_SIDE_PANEL_TYPE  = {};
export const closeSidePanel         = ( payload: CLOSE_SIDE_PANEL_TYPE ) => ( { type: CLOSE_SIDE_PANEL, payload } );

export const RESET_CANVAS           = 'RESET_CANVAS';
export type  RESET_CANVAS_TYPE      = { };
export const resetCanvas            = ( payload: RESET_CANVAS_TYPE ) => ( { type: RESET_CANVAS, payload } );
