// @flow

export type CONNECTOR_TYPE = {
  +fromNode: string,
  +fromPin: string,
  +toNode: string,
  +toPin: string,
};

export type CONNECTORS_STATE_TYPE = Array<CONNECTOR_TYPE>;

const connectors: CONNECTORS_STATE_TYPE = [
  // {"fromNode":"23","fromPin":"out","toNode":"11","toPin":"scene"},
  // {"fromNode":"14","fromPin":"out","toNode":"11","toPin":"camera"},
  // {"fromNode":"14","fromPin":"out","toNode":"35","toPin":"in5"},
  // {"fromNode":"35","fromPin":"out","toNode":"23","toPin":"children"},
  // {"fromNode":"45","fromPin":"rgb","toNode":"11","toPin":"bg_color"},
  // {"fromNode":"55","fromPin":"xyz","toNode":"14","toPin":"position"},
  // {"fromNode":"65","fromPin":"out","toNode":"35","toPin":"in0"},
  // {"fromNode":"79","fromPin":"out","toNode":"84","toPin":"in"},
  // {"fromNode":"89","fromPin":"xyz","toNode":"65","toPin":"rotation"},
  // {"fromNode":"84","fromPin":"out","toNode":"89","toPin":"y"},
  // {"fromNode":"79","fromPin":"out","toNode":"14","toPin":"aspect"}

  // { fromNode: "A", fromPin: "output1", toNode: "B", toPin: "input1" },
  // { fromNode: "A", fromPin: "output1", toNode: "C", toPin: "input1" },
  // { fromNode: "B", fromPin: "output1", toNode: "F", toPin: "input1" },
  // { fromNode: "B", fromPin: "output1", toNode: "E", toPin: "input2" },
  // { fromNode: "C", fromPin: "output1", toNode: "D", toPin: "input1" },
  // { fromNode: "D", fromPin: "output1", toNode: "E", toPin: "input3" },
  // { fromNode: "F", fromPin: "output1", toNode: "G", toPin: "input1" },
  // { fromNode: "G", fromPin: "output1", toNode: "E", toPin: "input1" },
  // { fromNode: "G", fromPin: "output1", toNode: "H", toPin: "input1" },

  // { fromNode: "B", fromPin: "output1", toNode: "C", toPin: "input1" },
];

export default connectors;
