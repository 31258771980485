// @flow

import React from 'react';
import { connect } from 'react-redux';

import { Form } from 'antd';

import Control from '../Control';

import { paramChange } from '../../actions/computes';

import type { NODE_PARAMS_ITEM_TYPE } from '../../data/nodes';

type Props = {
  nid: string,

  params: Array<NODE_PARAMS_ITEM_TYPE>,
  dispatchParamChange: ( nextParams: Array<NODE_PARAMS_ITEM_TYPE> ) => void,
};

type State = {

}

class NodeParams extends React.PureComponent<Props, State> {
  handleParamChange = ( paramName, paramValue ) => {
    const { params: prevParams, dispatchParamChange } = this.props;

    const nextParams = prevParams.map( ( prevParam ) => {
      if ( prevParam.name === paramName ) {
        return Object.assign( {}, prevParam, {
          value: paramValue,
        } );
      }
      return prevParam;
    } );

    dispatchParamChange( nextParams );
  }

  renderParamItems () {
    const { params } = this.props;

    return params.map( param => (
      <Form.Item key={ `param-item-${ param.name }` } label={ param.name }>
        <Control
          type={ param.type }
          onChange={ value => this.handleParamChange( param.name, value ) }
          value={ param.value }
          key={ `param-control-${ param.name }` }
          { ...param }
        />
      </Form.Item>
    ) );
  }

  render () {
    return (
      <Form>
        { this.renderParamItems() }
      </Form>
    );
  }
}

function select ( state, props ) {
  const { nid } = props;

  // TODO: reselect current node

  // get current node
  const node = state.nodes.find( _node => _node.nid === nid );

  return {
    params: node.params,
  };
}

function actions ( dispatch, props ) {
  const { nid } = props;

  return {
    dispatchParamChange: nextParams => dispatch( paramChange( { nid, params: nextParams } ) ),
  };
}

export default connect( select, actions )( NodeParams );
