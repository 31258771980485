// @flow

import React from 'react';
import onClickOutside from 'react-onclickoutside';

import TrashIcon from './TrashIcon';
import { bezierCurve, distance } from './bezierUtils';

type Props = {
  start: { x: number, y: number },
  end: { x: number, y: number },
  svgRect: { left: number, top: number },
  onRemove?: () => void,
  onClick?: ( e: SyntheticMouseEvent<> ) => void,
  onClickOutside?: ( e: SyntheticMouseEvent<> ) => void,
}

type State = {
  selected: boolean,
  position: {
    x: number,
    y: number,
  },
}

class Spline extends React.PureComponent<Props, State> {
  static defaultProps = {
    onRemove: null,
    onClick: null,
    onClickOutside: null,
  }

  state = {
    selected: false,
    position: { x: 0, y: 0 },
  }

  handleClick = ( e: SyntheticMouseEvent<> ) => {
    const { svgRect, onClick } = this.props;
    const { selected } = this.state;

    const { left, top } = svgRect;
    const { pageX, pageY } = e;

    this.setState( {
      selected: !selected,
      position: {
        x: pageX - left,
        y: pageY - top,
      },
    } );

    onClick && onClick( e );
  }

  handleClickOutside = ( e ) => {
    const { onClickOutside: _onClickOutside } = this.props;

    this.setState( { selected: false } );

    _onClickOutside && _onClickOutside( e );
  }

  handleRemove = () => {
    const { onRemove } = this.props;

    this.setState( { selected: false } );

    onRemove && onRemove();
  }

  render () {
    const { selected, position } = this.state;

    const { start, end } = this.props;

    const dist = distance( [ start.x, start.y ], [ end.x, end.y ] );

    const pathString = bezierCurve( start.x, // start x
      start.y, // start y
      start.x + dist * 0.25, // cp1 x
      start.y, // cp1 y
      end.x - dist * 0.75, // cp2 x
      end.y, // cp2 y
      end.x, // end x
      end.y ); // end y

    const className = `connector${ selected ? ' selected' : '' }`;

    return (
      <g>
        <circle cx={ start.x } cy={ start.y } r="3" fill="#337ab7" />
        <circle cx={ end.x } cy={ end.y } r="3" fill="#9191A8" />
        <path
          className="connector-click-area"
          d={ pathString }
          onClick={ this.handleClick }
        />
        <path
          className={ className }
          d={ pathString }
          onClick={ this.handleClick }
        />
        { selected
          ? (
            <TrashIcon
              position={ position }
              onClick={ this.handleRemove }
            />
          )
          : null }
      </g>

    );
  }
}

export default onClickOutside( Spline );
