// @flow

import React from 'react';

import type { NODE_OUTPUT_TYPE } from '../../data/nodes';

type Props = {
  item: NODE_OUTPUT_TYPE,
  index: number,
  onMouseDown: ( i: number ) => void,
}

type State = {
  hover: boolean
}

export default class NodeOutputListItem extends React.PureComponent<Props, State> {
  onMouseDown = ( e: SyntheticMouseEvent<> ) => {
    e.stopPropagation();
    e.preventDefault();

    const { onMouseDown, index } = this.props;
    onMouseDown( index );
  }

  noop = ( e: SyntheticMouseEvent<> ) => {
    e.stopPropagation();
    e.preventDefault();
  }

  render () {
    const { item: { name } } = this.props;

    return (
      <li>
        <a
          onClick={ this.noop }
          onMouseDown={ this.onMouseDown }
          href="#"
        >
          {name}
          <i className="fa fa-circle-o" />
        </a>
      </li>
    );
  }
}
