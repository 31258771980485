// @flow

import React from 'react';
import { connect } from 'react-redux';

import { Form } from 'antd';

import Control from '../Control';

import { setNodeSetting } from '../../actions/nodes';

import { makeGetSelectedNodeSettingBySettingName, getSelectedNodeNid } from '../../selectors/app';

import type { NODE_SETTINGS_ITEM_TYPE } from '../../data/nodes';

type Props = {
  name: string,
  nid: string,
  setting: NODE_SETTINGS_ITEM_TYPE,
  // dispatchSetNodeSetting: ( value: number | string | boolean ) => void,
  dispatch: Function,
}

function SettingItem ( props: Props ) {
  const { nid, setting, dispatch } = props;

  function dispatchSetNodeSetting ( value ) {
    dispatch( setNodeSetting( { nid, name: setting.name, value } ) );
  }

  return (
    <Form.Item
      label={ setting.name }
      colon={ false }
      labelCol={ { xs: { span: 8 } } }
      wrapperCol={ { xs: { span: 16 } } }
    >
      <Control
        type={ setting.type }
        onChange={ dispatchSetNodeSetting }
        value={ setting.value }
        key={ `setting-control-${ setting.name }` }
        { ...setting }
      />
    </Form.Item>
  );
}
function select ( state, props: Props ) {
  const getSelectedNodeSettingBySettingName = makeGetSelectedNodeSettingBySettingName( props.name );

  return ( _state, _props: Props ) => ( {
    nid: getSelectedNodeNid( _state ),
    setting: getSelectedNodeSettingBySettingName( _state ),
  } );
}

function actions ( dispatch, props: Props ) {
  return {
    dispatch,
  };
}

export default connect( select, actions )( SettingItem );
