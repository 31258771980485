import { filter } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';

// simplify writing of epics
function ce ( handlers ) {
  return combineEpics( ...Object.keys( handlers ).map( ( key ) => {
    const fn = handlers[ key ];
    return ( action$, state$ ) => fn(
      action$.pipe( filter( action => action.type === key ) ), state$
    );
  } ) );
}

export default ce;
