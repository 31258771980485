// @flow

import React from 'react';
import { connect } from 'react-redux';

import { Menu } from 'antd';

import { getToolbarItems } from '../selectors/toolbarItems';

import type { TOOLBAR_ITEM_TYPE } from '../data/toolbarItems';

type Props = {
  toolbarItems: Array<TOOLBAR_ITEM_TYPE>,
  handleAction: ( payload: mixed ) => mixed,
}

class Toolbar extends React.Component<Props> {
  handleClick = ( item: TOOLBAR_ITEM_TYPE ) => {
    if ( item.action ) {
      const { handleAction } = this.props;
      handleAction( item.action( item.payload ) );
    }
  }

  renderToolbarItems ( toolbarItems: Array<TOOLBAR_ITEM_TYPE> ) {
    return toolbarItems.map( ( item, i ) => {
      if ( item.submenu ) {
        const style = item.align === 'right' ? { float: 'right' } : {};
        return (
          <Menu.SubMenu title={ item.submenu } key={ `toolbar-submenu-${ item.submenu }` } style={ style }>
            { this.renderToolbarItems( item.items ) }
          </Menu.SubMenu>
        );
      }
      /* eslint-disable react/jsx-no-bind */
      return (
        <Menu.Item
          key={ `toolbar-item-${ item.name || 'Unnamed' }` }
          onClick={ this.handleClick.bind( this, item ) }
        >
          { item.name || 'Unnamed' }
        </Menu.Item>
      );
      /* eslint-enable react/jsx-no-bind */
    } );
  }

  render () {
    const { toolbarItems } = this.props;
    return (
      <Menu
        mode="horizontal"
        theme="dark"
        style={ { lineHeight: '64px' } }
        selectable={ false }
      >
        { this.renderToolbarItems( toolbarItems ) }
      </Menu>
    );
  }
}

function select ( state ) {
  return {
    toolbarItems: getToolbarItems( state ),
  };
}

function actions ( dispatch ) {
  return {
    handleAction: dispatch,
  };
}

export default connect( select, actions )( Toolbar );
