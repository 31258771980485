// @flow

import React from 'react';

import { bezierCurve, distance } from './bezierUtils';

type Props = {
  start: { x: number, y: number },
  svgRect: { left: number, top: number },
  scrollX: number,
  scrollY: number,
}

type State = {
  mousePos?: {
    x: number,
    y: number,
  }
}

class NewSpline extends React.PureComponent<Props, State> {
  state = {
  }

  componentDidMount () {
    document.addEventListener( 'mousemove', this.onMouseMove );
  }

  componentWillUnmount () {
    document.removeEventListener( 'mousemove', this.onMouseMove );
  }

  onMouseMove = ( e: MouseEvent ) => {
    e.stopPropagation();
    e.preventDefault();

    const { svgRect, scrollX, scrollY } = this.props;

    this.setState( {
      mousePos: {
        x: e.pageX + scrollX - svgRect.left,
        y: e.pageY + scrollY - svgRect.top,
      },
    } );
  }

  render () {
    const { start } = this.props;
    const { mousePos } = this.state;
    const end = mousePos ? { x: mousePos.x, y: mousePos.y } : start;
    const dist = distance( [ start.x, start.y ], [ end.x, end.y ] );
    const pathString = bezierCurve( start.x, // start x
      start.y, // start y
      start.x + dist * 0.25, // cp1 x
      start.y, // cp1 y
      end.x - dist * 0.75, // cp2 x
      end.y, // cp2 y
      end.x, // end x
      end.y ); // end y
    return (
      <g>
        <circle cx={ start.x } cy={ start.y } r="3" fill="#337ab7" />
        <circle cx={ end.x } cy={ end.y } r="3" fill="#9191A8" />
        <path className="connector-new" d={ pathString } />
      </g>
    );
  }
}

export default NewSpline;
