// @flow

export type APP_STATE_TYPE = {
  selectedNodeNid: boolean,
  sidePanelCollapsed: boolean,
};

const app: APP_STATE_TYPE = {
  // node selection
  selectedNodeNid: false,

  // settings side panel
  sidePanelCollapsed: true,
};

export default app;
