// @flow

import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { hot } from 'react-hot-loader';

// import components
import Layout from './Layout';

// import data store
import { store, persistor } from '../store';

function Root () {
  return (
    <Provider store={ store }>
      <PersistGate loading={ null } persistor={ persistor }>
        <Layout />
      </PersistGate>
    </Provider>
  );
}

export default hot( module )( Root );
