// @flow

// import data slices
import nodes from './nodes';
import connectors from './connectors';
import toolbarItems from './toolbarItems';
import app from './app';

import type { NODES_STATE_TYPE } from './nodes';
import type { CONNECTORS_STATE_TYPE } from './connectors';
import type { TOOLBARITEMS_STATE_TYPE } from './toolbarItems';
import type { APP_STATE_TYPE } from './app';

export type STATE_TYPE = {
  nodes: NODES_STATE_TYPE,
  connectors: CONNECTORS_STATE_TYPE,
  toolbarItems: TOOLBARITEMS_STATE_TYPE,
  app: APP_STATE_TYPE,
};

// combine data slices into single store
const initialState: STATE_TYPE = {
  nodes,
  connectors,
  toolbarItems,
  app,
};

export default initialState;
