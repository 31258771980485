import * as L from 'partial.lenses';
import cr from './cr';

import {
  SELECT_NODE, DESELECT_NODE, OPEN_SIDE_PANEL, CLOSE_SIDE_PANEL, RESET_CANVAS,
} from '../actions/app';

import type { APP_STATE_TYPE } from '../data/app';
import type {
  SELECT_NODE_TYPE,
  DESELECT_NODE_TYPE,
  OPEN_SIDE_PANEL_TYPE,
  CLOSE_SIDE_PANEL_TYPE,
  RESET_CANVAS_TYPE,
} from '../actions/app';

type ACTION_TYPE<T> = {
  type: string,
  payload: T
}

/* eslint max-len: ["error", { "code": 150 }] */

const app = cr( [], {
  [ SELECT_NODE ]: ( appState: APP_STATE_TYPE, action: ACTION_TYPE<SELECT_NODE_TYPE> ): APP_STATE_TYPE => {
    const { nid } = action.payload;

    return L.set(
      'selectedNodeNid',
      nid,
      appState
    );
  },
  [ DESELECT_NODE ]: ( appState: APP_STATE_TYPE, action: ACTION_TYPE<DESELECT_NODE_TYPE> ): APP_STATE_TYPE => L.set(
    'selectedNodeNid',
    false,
    appState
  ),
  [ OPEN_SIDE_PANEL ]: ( appState: APP_STATE_TYPE, action: ACTION_TYPE<OPEN_SIDE_PANEL_TYPE> ): APP_STATE_TYPE => L.set(
    'sidePanelCollapsed',
    false,
    appState
  ),
  [ CLOSE_SIDE_PANEL ]: ( appState: APP_STATE_TYPE, action: ACTION_TYPE<CLOSE_SIDE_PANEL_TYPE> ): APP_STATE_TYPE => L.set(
    'sidePanelCollapsed',
    true,
    appState
  ),
  [ RESET_CANVAS ]: ( appState: APP_STATE_TYPE, action: ACTION_TYPE<RESET_CANVAS_TYPE> ): APP_STATE_TYPE => (
    {
      ...appState,
      selectedNodeNid: false,
      sidePanelCollapsed: true,
    }
  ),
} );

export default app;
