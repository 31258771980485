// @flow

import React from 'react';
import { connect } from 'react-redux';

import { Layout as AntdLayout } from 'antd';

import Toolbar from './Toolbar';
import NodeGraph from './NodeGraph';
import SidePanel from './SidePanel';

const { Header, Content, Sider } = AntdLayout;

type Props = {
  sidePanelCollapsed?: boolean
};

type State = {
  scrollX: number,
  scrollY: number,
};

class Layout extends React.PureComponent<Props, State> {
  static defaultProps = {
    sidePanelCollapsed: true,
  }

  state = {
    scrollX: 0,
    scrollY: 0,
  }

  onScroll = ( e ) => {
    this.setState( {
      scrollX: e.target.scrollLeft,
      scrollY: e.target.scrollTop,
    } );
  }

  render () {
    const { scrollX, scrollY } = this.state;
    const { sidePanelCollapsed } = this.props;

    return (
      <AntdLayout className="layout">
        <Header className="header">
          <h1 className="header-logo">
            Datavis
          </h1>
          <Toolbar />
        </Header>
        <AntdLayout>
          <Content
            className="content-canvas"
            onScroll={ this.onScroll }
          >
            <NodeGraph scrollX={ scrollX } scrollY={ scrollY } />
          </Content>
          <Sider
            width={ 280 }
            collapsed={ sidePanelCollapsed }
            collapsible
            collapsedWidth={ 0 }
            trigger={ null }
          >
            <SidePanel />
          </Sider>
        </AntdLayout>

      </AntdLayout>
    );
  }
}

function select ( state ) {
  return {
    sidePanelCollapsed: state.app.sidePanelCollapsed,
  };
}

function actions ( dispatch ) {
  return {

  };
}

export default connect( select, actions )( Layout );
