// @flow

import React from 'react';
import { connect } from 'react-redux';
import isEqual from 'react-fast-compare';

import { Card, Icon, Form } from 'antd';

import SettingItem from './SettingItem';
import PropertyItem from './PropertyItem';

import {
  getSelectedNodeNid, getSelectedNodeNodeType,
  getSelectedNodeSettingNames, getSelectedNodePropertyNames,
} from '../../selectors/app';

type Props = {
  nid: string,
  nodeType: string,
  settingNames?: Array<string>,
  propertyNames?: Array<string>,
}

type State = {
}

class SidePanel extends React.Component<Props, State> {
  static defaultProps = {
    settingNames: [],
    propertyNames: [],
  }

  shouldComponentUpdate ( nextProps, nextState ) {
    const { settingNames, propertyNames, nid } = this.props;
    return (
      !isEqual( settingNames, nextProps.settingNames ) ||
      !isEqual( propertyNames, nextProps.propertyNames ) ||
      nid !== nextProps.nid
    );
  }

  renderSettingItems = () => {
    const { settingNames = [] } = this.props;
    return settingNames.map( settingName => ( <SettingItem name={ settingName } key={ `setting-item-${ settingName }` } /> ) );
  }

  renderPropertyItems = () => {
    const { propertyNames = [] } = this.props;
    return propertyNames.map( propertyName => ( <PropertyItem name={ propertyName } key={ `property-item-${ propertyName }` } /> ) );
  }

  render () {
    const { nid, nodeType, settingNames = [] } = this.props;
    const hasSettings = !!settingNames.length;

    if ( !nid ) {
      return (
        <div className="side-panel">
          <div className="side-panel-head">
            <h4>
              <Icon type="loading" />
                Loading
            </h4>
          </div>
        </div>
      );
    }

    return (
      <div className="side-panel">
        <div className="side-panel-head">
          <h4>
            <Icon type="menu-unfold" />
            { nodeType }
          </h4>
          <span>
            id:
            <i>
              { nid }
            </i>
          </span>
        </div>

        <div className="side-panel-content">
          <Card className="side-panel-card" title="Settings">
            { hasSettings && (
              <Form>
                { this.renderSettingItems() }
              </Form>
            ) }
            { !hasSettings && (
              <span>
                There are no settings for this node.
              </span>
            ) }
          </Card>

          <Card className="side-panel-card" title="Properties">
            <Form>
              { this.renderPropertyItems() }
            </Form>
          </Card>
        </div>
      </div>
    );
  }
}

function select ( state ) {
  return {
    nid: getSelectedNodeNid( state ),
    nodeType: getSelectedNodeNodeType( state ),
    settingNames: getSelectedNodeSettingNames( state ),
    propertyNames: getSelectedNodePropertyNames( state ),
  };
}

function actions ( dispatch ) {
  return {
  };
}

export default connect( select, actions )( SidePanel );
