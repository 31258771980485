// @flow

export type NODE_DATA_TYPE = Array<Array<mixed>>;

export type NODE_META_ITEM_TYPE = {
  +name: string,
  +type: string,
};

export type NODE_PARAMS_ITEM_TYPE = {
  +name: string,
  +type: string,
  +value: number | string | boolean,
  +options?: Array<{ name: string, value: number | string | boolean }>,
};

export type NODE_SETTINGS_ITEM_TYPE = {
  +name: string,
  +type: string,
  +value: number | string | boolean,
  // plus other control-specific properties e.g. options
};

export type NODE_PROPERTIES_ITEM_TYPE = {
  +name: string,
  +type: string,
  +value: number | string | boolean,
  // plus other control-specific properties e.g. options
};

export type NODE_INPUT_TYPE = {
  +name: string
};

export type NODE_OUTPUT_TYPE = {
  +name: string
};

export type NODE_TYPE = {
  +nid: string,
  +data: NODE_DATA_TYPE,
  +meta: Array<NODE_META_ITEM_TYPE>,
  +params: Array<NODE_PARAMS_ITEM_TYPE>,
  +settings: Array<NODE_SETTINGS_ITEM_TYPE>,
  +properties: Array<NODE_PROPERTIES_ITEM_TYPE>,
  +nodeType: string,
  +x: number,
  +y: number,
  +inputs: Array<NODE_INPUT_TYPE>,
  +outputs: Array<NODE_OUTPUT_TYPE>,
};

export type NODES_STATE_TYPE = Array<NODE_TYPE>;

const nodes: NODES_STATE_TYPE = [
/*
  {
    nid: 'Afbdfghbreyg434',
    data: [
      // [ 1, 2, 3 ],
      // [ 4, 5, 6 ],
      // [ 7, 8, 9 ],
      // [ 10, 11, 12 ],
      // [ 13, 14, 15 ],
    ],
    meta: [
      // { name: 'column1' },
      // { name: 'column2' },
      // { name: 'column3' },
    ],
    params: [],
    nodeType: 'TextNode',
    x: 50,
    y: 250,
    inputs: [],
    outputs: [ { name: 'column1' }, { name: 'column2' }, { name: 'column3' } ],
  },
  {
    nid: 'B',
    data: [],
    meta: [],
    params: [
      { name: 'Operand', value: 0 },
    ],
    nodeType: 'math/OperationNode',
    x: 500,
    y: 250,
    inputs: [ { name: 'value' } ],
    outputs: [ { name: 'column1' }, { name: 'column2' }, { name: 'column3' } ],
  },
  {
    nid: 'C', data: [], meta: [], params: [], nodeType: 'utils/ViewNode',
    x: 950, y: 250, inputs: [ { name: 'column' } ], outputs: [ ],
  },
  {
    nid: 'D', data: [], meta: [], params: [], nodeType: 'utils/ViewNode',
    x: 500, y: 500, inputs: [], outputs: [ ],
  },
*/
];

export const DEFAULT_NODE_PROPERTIES = [
  {
    name: 'Size',
    type: 'buttongroup',
    value: 'mini',
    options: [
      { name: 'Small', value: 'mini' },
      { name: 'Large', value: 'maxi' },
    ],
  },
];

export default nodes;
