// @flow

import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

// import root reducer
import rootReducer from './reducers';

// import root epic
import rootEpic from './epics';

// import default data
import initialState from './data';

// enable dev tools extension
/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable no-underscore-dangle */

// create epic middleware
const epicMiddleware = createEpicMiddleware();

// apply router middleware
const enhancers = composeEnhancers(
  applyMiddleware(
    epicMiddleware
  )
);

// config for redux persist, blacklist toolBarItems state
const persistConfig = {
  key: 'root',
  storage,
  blacklist: [ 'toolbarItems' ],
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer( persistConfig, rootReducer );

// create and export store with redux-persisted reducer
export const store = createStore(
  persistedReducer,
  initialState,
  enhancers
);

// enable root epic to run
const epic$ = new BehaviorSubject( rootEpic );
const hotReloadingEpic = ( ...args ) => epic$.pipe( switchMap( epic => epic( ...args ) ) );
epicMiddleware.run( hotReloadingEpic );

/* eslint-disable global-require */

// enable hot reloading of reducers
if ( module.hot ) {
  module.hot.accept( './reducers/', () => {
    const nextRootReducer = require( './reducers/index' ).default;
    store.replaceReducer( nextRootReducer );
  } );
}

// enable hot reloading of epics
if ( module.hot ) {
  module.hot.accept( './epics/', () => {
    const nextRootEpic = require( './epics/index' ).default;
    epic$.next( nextRootEpic );
  } );
}

export const persistor = persistStore( store );
