// @flow

/* eslint-disable no-multi-spaces, max-len */
export const ADD_CONNECTOR          = 'ADD_CONNECTOR';
export type  ADD_CONNECTOR_TYPE     = { fromNode: string, fromPin: string, toNode: string, toPin: string };
export const addConnector           = ( payload: ADD_CONNECTOR_TYPE ) => ( { type: ADD_CONNECTOR, payload } );

export const REMOVE_CONNECTOR       = 'REMOVE_CONNECTOR';
export type  REMOVE_CONNECTOR_TYPE  = { fromNode: string, fromPin: string, toNode: string, toPin: string };
export const removeConnector        = ( payload: REMOVE_CONNECTOR_TYPE ) => ( { type: REMOVE_CONNECTOR, payload } );
